import { axiosPrivateInstance, axiosInstance } from "./instance";

const getCashAnalyze = (date, pagination = 1) => {
  return axiosPrivateInstance.get(`/list-check-cashbox/${date}/1?page=${pagination}&limit=50`);
};

const getPlans = () => {
  return axiosInstance.get(`/list-plans`);
};

const postEditPlan = (data) => {
  return axiosPrivateInstance.post(`/udpdate-info-plan-mba`, data);
};

export const getbusinessByMail = (value) => {
  return axiosPrivateInstance.post(`/search-mail-exists-for-business`, value);
};

const getConsumeAnalyzeBusiness = (key) => {
  return axiosPrivateInstance.get(`/detail-deal/${key}`);
};

const getConsumeAnalyzeBusinessBranch = (key, branch) => {
  return axiosPrivateInstance.get(`/detail-deal/${key}?isBranch=${branch}`);
};

const postChangeStatusBusiness = (body) => {
  return axiosPrivateInstance.post(`/status-business`, body);
};

const postChangeBlockForPay = (body) => {
  return axiosPrivateInstance.post(`/manage-suscription-for-business `, body);
};

const activeImportClients = (id) => {
  return axiosPrivateInstance.get(`/enable-import-client/${id}`);
};

export {
  getCashAnalyze,
  getConsumeAnalyzeBusiness,
  activeImportClients,
  getConsumeAnalyzeBusinessBranch,
  postChangeStatusBusiness,
  postChangeBlockForPay,
  getPlans,
  postEditPlan,
};
